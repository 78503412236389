@import '../../../scss/theme-bootstrap';

// nav search
.esearch-nav {
  &__popular {
    color: $color-gray;
    text-align: center;
    width: 100%;
    @if $search_modernized_design == true {
      text-transform: none;
      padding-top: 0;
    } @else {
      text-transform: uppercase;
      padding-top: 2em;
    }
    // styles added in CMS
    &-list {
      font-size: 2em;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      a {
        color: $color-gray;
      }
    }
  }

  @if $search_modernized_design == true {
    &__form {
      &-close {
        display: block;
        position: absolute;
        top: 10px;
        #{$rdirection}: 0;
        border-color: transparent;
        padding: 0;
        border-width: 0;
        background: 0 0;
        min-width: auto;
        width: 24px;
        height: 24px;
        @include breakpoint($portrait-up) {
          #{$rdirection}: 10px;
        }
        svg {
          width: 14px;
          height: 14px;
          fill: $color-tmo-light-gray;
        }
      }
      &-clear {
        position: absolute;
        top: 8px;
        #{$rdirection}: 50px;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .content {
      .content {
        display: flex;
        flex-wrap: wrap;
        .elc__popular-header,
        .elc__popular-header-2 {
          flex-basis: 100%;
          text-align: start;
          font-size: 18px;
          font-weight: 700;
          line-height: get-line-height(18px, 23px);
          color: $color-off-black;
          order: 1;
          padding-bottom: 16px;
          @include breakpoint($portrait-up) {
            flex-basis: 25%;
            order: 1;
            padding-bottom: 16px;
          }
          &-2 {
            flex-basis: 100%;
            order: 3;
            @include breakpoint($portrait-up) {
              flex-basis: 75%;
              order: 1;
            }
          }
        }
        .product-grid-wrapper {
          border-top: none;
          @include breakpoint($portrait-up) {
            flex-basis: 75%;
          }
          .product-grid__content {
            .product-grid__item {
              @include breakpoint($portrait-up) {
                padding-#{$rdirection}: 25px;
              }
            }
            .product-grid__item:last-child {
              @include breakpoint($portrait-up) {
                display: none;
              }
              @include breakpoint($landscape-up) {
                display: block;
              }
            }
          }
          .product-brief {
            &__sku-select,
            &__gap,
            &__gap-footer,
            &__add-to-bag,
            &__shades,
            &__flag-text {
              display: none;
            }
            &__name {
              font-weight: 700;
              font-size: 16px;
            }
            &__header {
              width: 100px;
              height: 100px;
              @include breakpoint($portrait-up) {
                width: 220px;
                height: 220px;
              }
              .product__image-link {
                img {
                  max-height: 100px;
                  @include breakpoint($portrait-up) {
                    max-height: 220px;
                  }
                }
                .product__image--alt {
                  display: none !important;
                }
              }
            }
            &__rating {
              height: auto;
            }
            &__footer {
              justify-content: start;
            }
            &__container {
              flex-direction: row;
              padding: 0 0 16px;
              gap: 16px;
              @include breakpoint($portrait-up) {
                flex-flow: column nowrap;
                padding: 0;
                gap: 0;
              }
            }
          }
          .product-sku-price {
            text-align: start;
            @include breakpoint($portrait-up) {
              padding-top: 8px;
            }
            &__value {
              font-size: 16px;
              font-weight: 700;
              &-link {
                display: flex;
              }
            }
            &2__value {
              order: 2;
              line-height: 2;
            }
            &.has-sale {
              .product-sku-price__value {
                order: 1;
                color: $color-tmo-error;
                margin-inline-end: 5px;
              }
            }
          }
        }
        .esearch-nav {
          &__popular {
            &-list {
              flex-basis: 100%;
              font-size: 16px;
              font-weight: 400;
              line-height: get-line-height(16px, 21px);
              text-align: start;
              order: 2;
              @include breakpoint($portrait-up) {
                flex-basis: 25%;
              }
              a {
                color: $color-off-black;
              }
            }

            &__item {
              padding-bottom: 16px;
              @include breakpoint($portrait-up) {
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
    .no-result {
      @include body--small;
      font-weight: 700;
      @include breakpoint($portrait-up) {
        @include headline--h5;
        font-weight: 400;
      }
    }
    .no-results {
      margin-bottom: 16px;
    }
  }
}

.typeahead-wrapper {
  text-align: $ldirection;
  width: 100%;
  .see-results {
    color: $color-gray;
    width: 100%;
    clear: both;
    @if $search_modernized_design == true {
      text-transform: none;
      color: $color-off-black;
      padding-bottom: 16px;
      @include breakpoint($portrait-up) {
        padding-bottom: 0;
      }
      a,
      a:visited {
        color: $color-off-black;
        font-size: 16px;
      }
      .search-suggestions__link {
        display: flex;
        justify-content: space-between;
        @include breakpoint($portrait-up) {
          display: block;
        }
      }
    } @else {
      text-transform: uppercase;
      color: $color-gray;
      a,
      a:visited {
        color: $color-gray;
      }
    }

    .search-term {
      color: $color-off-black;
      font-weight: bold;
      text-transform: none;
    }
    &--upper {
      @if $search_modernized_design == true {
        .search-results {
          text-decoration: underline;
          @include breakpoint($portrait-up) {
            padding-inline-start: 16px;
          }
        }
        .search-count {
          font-size: 16px;
        }
      } @else {
        padding: 1em 0;
        .search-results {
          display: none;
        }
      }

    }
    &--lower {
      padding: 2em 0 0;
      text-align: center;
      .search-count {
        display: none;
      }
      @if $search_modernized_design == true {
        display: none !important;
      } @else {
        .search-results {
          @include button();
        }
      }
    }
  }
  .esearch-product__headline {
    @if $search_modernized_design == true {
      text-transform: lowercase;
      font-weight: 700;
      font-size: 16px;
      line-height: get-line-height(16px, 21px);
    } @else {
      text-transform: uppercase;
    }
  }
  @if $search_modernized_design == true {
    .esearch-product__header {
      min-width: 95px;
      .esearch-product--typeahead__img {
        max-height: 100px;
      }
    }
    .esearch-product__headline {
      margin-bottom: 3px;
    }
    .product-rating__stars-wrapper {
      width: 100%;
    }
    .esearch-product__price {
      margin-top: 7px;
      @include breakpoint($portrait-up) {
        margin-top: 16px;
      }
      @include breakpoint($xlarge-up) {
        display: flex;
      }
      .product-sku-price {
        width: auto;
        &.has-sale {
          .product-sku-price__value {
            order: 1;
            color: $color-red-dark;
          }
        }
      }
      .product-sku-price__value-link {
        display: flex;
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: get-line-height(16px, 21px);
          color: $black;
        }
        .product-sku-price2__value {
          order: 2;
          font-weight: 400;
          padding-#{$ldirection}: 5px;
          color: $color-off-black;
        }
      }
      .overlay-link,
      .product-points,
      .product-full__installment_price {
        display: none;
      }
    }
  }
}
